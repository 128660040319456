import { useQuery } from '@tanstack/react-query';
import CreateAuthAxiosInstance from 'utils/authAxios';

export const useGetElectionDetail = () => {
  const authAxios = CreateAuthAxiosInstance();

  const {
    data: electionDetail,
    refetch,
    isFetching
  } = useQuery({
    queryKey: ["electionDetail"],
    queryFn: () => authAxios('api/v1/candidate/election').then(data => data.data),
    refetchOnWindowFocus: false
    // staleTime: Infinity
  });

  return {
    electionDetail,
    refetch,
    isFetching
  }
}
