import ScriptTabs from './tabs/ScriptTabs';
import CandidateScript from './CandidateScript';
import VolunteerScript from './VolunteerScript';

const ScriptContent = () => {
   

  return (
    <div className='row mt-5'>
        <div className="col-md-3">
          <ScriptTabs />
        </div>
        <div className="col-md-8 offset-md-1">
        <div className="tab-content" id="v-pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="v-pills-calls"
          role="tabpanel"
          aria-labelledby="v-pills-calls-tab"
          tabIndex={0}
        >
          <CandidateScript />
          <VolunteerScript />
        </div>
        <div
          className="tab-pane fade"
          id="v-pills-canvassing"
          role="tabpanel"
          aria-labelledby="v-pills-canvassing-tab"
          tabIndex={0}
        >
          <CandidateScript />
          <VolunteerScript />
        </div>

        <div
          className="tab-pane fade"
          id="v-pills-callTime"
          role="tabpanel"
          aria-labelledby="v-pills-callTime-tab"
          tabIndex={0}
        >
          <CandidateScript />
         
        </div>
      </div>
        </div>
      
    </div>
  )
}

export default ScriptContent
