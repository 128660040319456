import SideNavbar from "components/common/navbar/SideNavbar";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import TrashIcon from "img/svg/TrashIcon";
import CheckSuccess from "img/svg/checkSuccess";
import FileIcon from "img/svg/fileIcon";
import React from "react";
import { Link } from "react-router-dom";
import ExpertListSkeleton from "./ExpertListSkeleton";

const ExpertList = () => {
  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />

      <div className="wrapper active-cont">
        <main>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <ul
                  className="nav nav-tabs nav-flush scroll-x nav-settings position-relative bg-transparent"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="ad-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#ad-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="ad-tab-pane"
                      aria-selected="true"
                    >
                      Digital Ad (3)
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="mail-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#mail-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="mail-tab-pane"
                      aria-selected="false"
                    >
                      Direct Mail (1)
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="website-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#website-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="website-tab-pane"
                      aria-selected="false"
                    >
                      Website (0)
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="merchandise-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#merchandise-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="merchandise-tab-pane"
                      aria-selected="false"
                    >
                      Merchandise (0)
                    </button>
                  </li>
                </ul>

                {/* note: show tab-noData class if list is 0 */}
                <div className="tab-content tab-minHeight" id="myTabContent">
                  <div
                    className="tab-pane w-100 fade show active"
                    id="ad-tab-pane"
                    role="tabpanel"
                    aria-labelledby="ad-tab"
                    tabIndex={0}
                  >
                    {/* when loading: card skeleton */}
                   <ExpertListSkeleton />


                    {/* expert list */}
                    <div className="card shadow-2 mt-4">
                      <div className="card-body">
                        <div className="row align-items-end">
                          <div className="col-md-9">
                            <div className="media media-lg gap-3">
                              <div className="media-header border-0">
                                <img
                                  src={require("../../img/avatar.png")}
                                  className="img-fluid"
                                  alt="User Avatar"
                                />
                              </div>

                              <div className="media-body">
                                <h5 className="mb-0">Alexa Johnson</h5>

                                <span className="badge badge-extended-outline mt-1 rounded-pill">
                                  {" "}
                                  Digital Ad
                                </span>
                                <p className="mt-3 text-muted">
                                  Alex consistently delivers impactful results
                                  in connecting candidates with voters through
                                  mail campaigns, showcasing expertise and
                                  dedication in crafting effective communication
                                  strategies.
                                </p>

                                <div className="d-flex gap-2">
                                  <span className="badge badge-primary5 font-regular rounded-pill">
                                    {" "}
                                    Mail{" "}
                                  </span>
                                  <span className="badge badge-primary5 font-regular rounded-pill">
                                    {" "}
                                    Democrats{" "}
                                  </span>
                                  <span className="badge badge-primary5 font-regular rounded-pill">
                                    {" "}
                                    Rural{" "}
                                  </span>
                                  <span className="badge badge-primary5 font-regular rounded-pill">
                                    {" "}
                                    BIPOC{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="mt-3 text-center">
                              <button
                                type="submit"
                                className="btn btn-primary btn-xs rounded-pill w-100 d-block mb-2"
                              >
                                {" "}
                                Schedule{" "}
                              </button>
                              <span className="fs-8 text-muted">
                                {" "}
                                Hourly rate{" "}
                              </span>
                              <span className="body-5 d-block mb-0">
                                {" "}
                                $70.00 
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card shadow-2 mt-4">
                      <div className="card-body">
                        <div className="row align-items-end">
                          <div className="col-md-9">
                            <div className="media media-lg gap-3">
                              <div className="media-header border-0">
                                <img
                                  src={require("../../img/avatar-2.png")}
                                  className="img-fluid"
                                  alt="User Avatar"
                                />
                              </div>

                              <div className="media-body">
                                <h5 className="mb-0"> Dianne Russel </h5>

                                <span className="badge badge-extended-outline mt-1 rounded-pill">
                                  {" "}
                                  Digital Ad
                                </span>
                                <p className="mt-3 text-muted">
                                  Alex consistently delivers impactful results
                                  in connecting candidates with voters through
                                  mail campaigns, showcasing expertise and
                                  dedication in crafting effective communication
                                  strategies.
                                </p>

                                <div className="d-flex gap-2">
                                  <span className="badge badge-primary5 font-regular rounded-pill">
                                    {" "}
                                    Libertarian{" "}
                                  </span>
                                  <span className="badge badge-primary5 font-regular rounded-pill">
                                    {" "}
                                    Metropolitian{" "}
                                  </span>
                                  <span className="badge badge-primary5 font-regular rounded-pill">
                                    {" "}
                                    BIPOC{" "}
                                  </span>
                                  <span className="badge badge-primary5 font-regular rounded-pill">
                                    {" "}
                                    Get-Out-the-Voter{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="mt-3 text-center">
                              <button
                                type="submit"
                                className="btn btn-primary btn-xs rounded-pill w-100 d-block mb-2"
                              >
                                {" "}
                                Schedule{" "}
                              </button>
                              <span className="fs-8 text-muted">
                                {" "}
                                Hourly rate{" "}
                              </span>
                              <span className="body-5 d-block mb-0">
                                {" "}
                                $70.00 
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> 
                    <div className="card shadow-2 mt-4">
                      <div className="card-body">
                        <div className="row align-items-end">
                          <div className="col-md-9">
                            <div className="media media-lg gap-3">
                              <div className="media-header border-0">
                                <img
                                  src={require("../../img/avatar-2.png")}
                                  className="img-fluid"
                                  alt="User Avatar"
                                />
                              </div>

                              <div className="media-body">
                                <h5 className="mb-0"> Dianne Russel </h5>

                                <span className="badge badge-extended-outline mt-1 rounded-pill">
                                  {" "}
                                  Digital Ad
                                </span>
                                <p className="mt-3 text-muted">
                                  Alex consistently delivers impactful results
                                  in connecting candidates with voters through
                                  mail campaigns, showcasing expertise and
                                  dedication in crafting effective communication
                                  strategies.
                                </p>

                                <div className="d-flex gap-2">
                                  <span className="badge badge-primary5 font-regular rounded-pill">
                                    {" "}
                                    Libertarian{" "}
                                  </span>
                                  <span className="badge badge-primary5 font-regular rounded-pill">
                                    {" "}
                                    Metropolitian{" "}
                                  </span>
                                  <span className="badge badge-primary5 font-regular rounded-pill">
                                    {" "}
                                    BIPOC{" "}
                                  </span>
                                  <span className="badge badge-primary5 font-regular rounded-pill">
                                    {" "}
                                    Get-Out-the-Voter{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="mt-3 text-center">
                              <button
                                type="submit"
                                className="btn btn-primary btn-xs rounded-pill w-100 d-block mb-2"
                              >
                                {" "}
                                Schedule{" "}
                              </button>
                              <span className="fs-8 text-muted">
                                {" "}
                                Hourly rate{" "}
                              </span>
                              <span className="body-5 d-block mb-0">
                                {" "}
                                $70.00 
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* when list is 0 */}
                    {/* <div className="noData d-flex gap-2 flex-row align-items-center">
                    <InfoIconSecondary />
                    <p className="m-0 text-muted fs-7">
                      {" "}
                      No digital ads experts 
                    </p>
                  </div> */}
                  </div>

                  <div
                    className="tab-pane w-100 fade"
                    id="mail-tab-pane"
                    role="tabpanel"
                    aria-labelledby="mail-tab"
                    tabIndex={0}
                  >

                    {/* when loading: card skeleton */}
                   {/* <ExpertListSkeleton /> */}

                    {/* Direct Mail list  */}
                    <div className="card shadow-2 mt-4">
                      <div className="card-body">
                        <div className="row align-items-end">
                          <div className="col-md-9">
                            <div className="media media-lg gap-3">
                              <div className="media-header border-0">
                                <img
                                  src={require("../../img/avatar.png")}
                                  className="img-fluid"
                                  alt="User Avatar"
                                />
                              </div>

                              <div className="media-body">
                                <h5 className="mb-0">Alexa Johnson</h5>

                                <span className="badge badge-extended-outline mt-1 rounded-pill">
                                  {" "}
                                  Digital Ad
                                </span>
                                <p className="mt-3 text-muted">
                                  Alex consistently delivers impactful results
                                  in connecting candidates with voters through
                                  mail campaigns, showcasing expertise and
                                  dedication in crafting effective communication
                                  strategies.
                                </p>

                                <div className="d-flex gap-2">
                                  <span className="badge badge-primary5 font-regular rounded-pill">
                                    {" "}
                                    Mail{" "}
                                  </span>
                                  <span className="badge badge-primary5 font-regular rounded-pill">
                                    {" "}
                                    Democrats{" "}
                                  </span>
                                  <span className="badge badge-primary5 font-regular rounded-pill">
                                    {" "}
                                    Rural{" "}
                                  </span>
                                  <span className="badge badge-primary5 font-regular rounded-pill">
                                    {" "}
                                    BIPOC{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="mt-3 text-center">
                              <button
                                type="submit"
                                className="btn btn-primary btn-xs rounded-pill w-100 d-block mb-2"
                              >
                                {" "}
                                Schedule{" "}
                              </button>
                              <span className="fs-8 text-muted">
                                {" "}
                                Hourly rate{" "}
                              </span>
                              <span className="body-5 d-block mb-0">
                                {" "}
                                $70.00
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    
                    {/* when list is 0 */}
                    {/* <div className="noData d-flex gap-2 flex-row align-items-center">
                      <InfoIconSecondary />
                      <p className="m-0 text-muted fs-7">
                        {" "}
                        No digital ads experts
                      </p>
                    </div> */}
                  </div>

                  <div
                    className="tab-pane w-100 fade show"
                    id="website-tab-pane"
                    role="tabpanel"
                    aria-labelledby="website-tab"
                    tabIndex={0}
                  >
                   
                    <div className="noData d-flex gap-2 flex-row align-items-center">
                      <InfoIconSecondary />
                      <p className="m-0 text-muted fs-7">
                        {" "}
                        No digital ads experts
                      </p>
                    </div>
                  </div>

                  <div
                    className="tab-pane w-100 fade show"
                    id="merchandise-tab-pane"
                    role="tabpanel"
                    aria-labelledby="merchandise-tab"
                    tabIndex={0}
                  >
                    
                    <div className="noData d-flex gap-2 flex-row align-items-center">
                      <InfoIconSecondary />
                      <p className="m-0 text-muted fs-7">
                        {" "}
                        No digital ads experts
                      </p>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ExpertList;
