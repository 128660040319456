import SideNavbar from "components/common/navbar/SideNavbar";
import Editor from "@monaco-editor/react";
import { faker } from "@faker-js/faker";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import toastNotify from "utils/toastNotify";
import Spinner from "templates/components/Spinner";

const ActBlueContribution = () => {
  const [isNewFaker, setIsNewFaker] = useState(false);
  const [endPoint, setEndPoint] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [payload, setPayload] = useState({
    donor: createDonor(),
    contribution: createContribution(),
    lineitems: lineItems,
    form: createForm(),
  });
  useEffect(() => {
    const contributionPayload = {
      donor: createDonor(),
      contribution: createContribution(),
      lineitems: lineItems,
      form: createForm(),
    };
    getEditorValue();
    setPayload(contributionPayload);
  }, [isNewFaker]);

  const handleSendNotification = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(endPoint, payload, {
        auth: {
          username: userName,
          password: password,
        },
      });

      console.log("Response:", response.data);
      toastNotify("success", "donation data sent!");
      setIsLoading(false);
    } catch (error: any) {
      console.error("Error:", error);
      setIsLoading(false);
      toastNotify("error", error.response.data.message);
    }
  };

  const generateNewPayload = (e: any) => {
    e.preventDefault();
    setIsNewFaker(!isNewFaker);
  };

  const editorRef = useRef<any>(null);
  const handleEditorDidMount = (editor: any) => {
    editorRef.current = editor;
  };

  const getEditorValue = () => {
    if (editorRef.current) {
      const editorValue = editorRef.current.getValue();
      console.log("Editor value:", editorValue);
      setPayload(editorValue);
    }
  };

  return (
    <div className="d-flex align-items-stretch">
      <SideNavbar />

      <div className="wrapper active-cont">
        <main>
          <h1>Send Contribution Notification </h1>
          <p>
            This tool is used for testing custom new integration with ActBlue
            Default Webhook. It generate new data with Faker on each request.
          </p>
          <form>
            <div className="row h-100">
              <div className="col-md-3">
                <div className="mb-3">
                  <label htmlFor="endUrl" className="form-label">
                    EndURL
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="endUrl"
                    onChange={(e) => setEndPoint(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="usernmae" className="form-label">
                    Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="usernmae"
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="payload" className="form-label">
                    Payload
                  </label>
                  <Editor
                    height="50vh"
                    theme="vs-dark"
                    defaultLanguage="json"
                    defaultValue={JSON.stringify(payload, null, 2)}
                    value={JSON.stringify(payload, null, 2)}
                    onMount={handleEditorDidMount}
                  />
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-link"
              onClick={generateNewPayload}
            >
              Generate Payload
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSendNotification}
            >
              {isLoading ? <Spinner /> : null}
              Send Notification
            </button>
          </form>
        </main>
      </div>
    </div>
  );
};
export default ActBlueContribution;

export function createDonor() {
  return {
    firstname: faker.person.firstName(),
    lastname: faker.person.lastName(),
    addr1: faker.location.streetAddress(),
    city: faker.location.city(),
    state: faker.location.state(),
    zip: faker.location.zipCode(),
    country: faker.location.country(),
    isEligibleForExpressLane: Math.random() < 0.5,
    employerData: createEmployerData(),
    email: faker.internet.email(),
    phone: faker.phone.number(),
  };
}

export function createEmployerData() {
  return {
    employer: faker.person.fullName(),
    occupation: faker.person.jobTitle(),
    employerAddr1: faker.location.streetAddress(),
    employerCity: faker.location.city(),
    employerState: faker.location.state(),
    employerCountry: faker.location.country(),
  };
}

export function createForm() {
  return {
    name: faker.person.fullName(),
    kind: "page",
    ownerEmail: faker.internet.email(),
    managingEntityName: null,
    managingEntityCommitteeName: null,
  };
}

export function createLineitem() {
  return {
    sequence: faker.number.int(),
    entityId: faker.number.int(),
    fecId: faker.number.int(),
    committeeName: faker.lorem.word(),
    amount: faker.number.float({ precision: 0.1 }) * 100,
    recurringAmount: faker.number.float({ precision: 0.2 }) * 100,
    paidAt: faker.date.past(),
    paymentId: faker.number.int(),
    lineItemId: faker.number.int(),
  };
}

export const lineItems = faker.helpers.multiple(createLineitem, { count: 3 });

export function creteCustomFileds() {
  return {
    label: faker.lorem.lines(),
    answer: faker.lorem.sentence(),
  };
}

export function createContribution() {
  return {
    createdAt: faker.date.past(),
    orderNumber: faker.number.int(),
    contributionForm: faker.lorem.lines(),
    refcodes: faker.lorem.slug(),
    refcode: null,
    refcode2: null,
    creditCardExpiration: faker.date.future(),
    recurringPeriod: Math.floor(Math.random() * 4),
    recurringDuration: faker.number.int(),
    weeklyRecurringSunset: null,
    abTestName: null,
    isRecurring: Math.random() < 0.5,
    isPaypal: Math.random() < 0.5,
    isMobile: Math.random() < 0.5,
    abTestVariation: null,
    isExpress: Math.random() < 0.5,
    withExpressLane: Math.random() < 0.5,
    expressSignup: Math.random() < 0.5,
    uniqueIdentifier: faker.lorem.word(),
    textMessageOption: faker.lorem.sentence(),
    giftDeclined: null,
    giftIdentifier: null,
    shippingName: null,
    shippingAddr1: null,
    shippingCity: null,
    shippingState: null,
    shippingZip: null,
    shippingCountry: null,
    smartBoostAmount: null,
    customFields: creteCustomFileds(),
    status: statuses[Math.floor(Math.random() * statuses.length)],
    thanksUrl: faker.internet.url(),
  };
}

const statuses = ["approved", "pending", "rejected"];
