import { useAuth0 } from "@auth0/auth0-react";
import SecurityDetails from "./security-details/SecurityDetails";
import DonorDetails from "./donor-details/DonorDetails";
import VoterDetails from "./voter-details/VoterDetails";
import PersonalDetails from "./personal-details/PersonalDetails";
import ProfessionalDetails from "./professional-details/ProfessionalDetails";
import IntegrationsDetails from "./integrations-details/IntegrationsDetails";
import { useSearchParams } from "react-router-dom";
import CallLogs from "./call-logs/CallLogs";
import useProfileDetails from "./useProfileDetails";
import { useEffect, useState } from "react";
import ScriptDetails from "./script/ScriptDetails";

const PROFILE_TABS = [
  {
    tabIndex: 0,
    tabId: "personal-tab",
    tabName: "My account",
  },
  {
    tabIndex: 1,
    tabId: "story-tab",
    tabName: "Professional details",
  },
  {
    tabIndex: 2,
    tabId: "voter-tab",
    tabName: "Voter",
  },
  {
    tabIndex: 3,
    tabId: "donor-tab",
    tabName: "Fundraise",
  },
  {
    tabIndex: 4,
    tabId: "script-tab",
    tabName: "Script",
  },
  {
    tabIndex: 5,
    tabId: "campaign-tab",
    tabName: "Integrations",
  },
  {
    tabIndex: 6,
    tabId: "call-log-tab",
    tabName: "Logs",
  },
  {
    tabIndex: 7,
    tabId: "security-tab",
    tabName: "Security",
  },
];

const Profile: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams({ tabIndex: "0" });
  const { isVerifiedPhoneNumber } = useProfileDetails();

  const [profileTabs, setProfileTabs] = useState(PROFILE_TABS);

  // useEffect(() => {
  //   if (isVerifiedPhoneNumber) {
  //     const callLogIndex = 5;
  //     const profileTabsCopy = [...profileTabs];
  //     profileTabsCopy.splice(callLogIndex, 0, {
  //       tabIndex: 5,
  //       tabId: "call-log-tab",
  //       tabName: "Logs",
  //     });
  //     setProfileTabs(profileTabsCopy);
  //   }
  // }, [isVerifiedPhoneNumber]);

  const handleTabChange = (tabId: number) => {
    setSearchParams((params) => {
      params.set("tabIndex", JSON.stringify(tabId));
      return params;
    });
  };
  const tabIndex = searchParams.get("tabIndex");
  const parsedTabIndex = tabIndex ? JSON.parse(tabIndex) : 0;

  return isAuthenticated ? (
    // <article className="column m-5 pt-3">
    //   {user?.picture && <img src={user.picture} alt={user?.name} />}
    //   <h2>{user?.name}</h2>
    //   <ul>
    //     {user &&
    //       Object.keys(user).map((objKey, i) => (
    //         <li key={i}>
    //           {objKey}: {user[objKey]}{" "}
    //         </li>
    //       ))}
    //   </ul>
    // </article>
    <div className="d-flex align-items-stretch">
      {/* <SideNavbar /> */}

      <div className="wrapper active-cont bg-white">
        <main>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <ul
                  className="nav nav-tabs nav-flush scroll-x nav-settings position-relative bg-transparent"
                  id="myTab"
                  role="tablist"
                >
                  {profileTabs.map((tab, index) => {
                    const { tabIndex, tabId, tabName } = tab;
                    const tabPane = `${tabId}-pane`;
                    return (
                      <li className="nav-item" role="presentation" key={index}>
                        <button
                          className={`nav-link ${
                            tabIndex === parsedTabIndex ? "active" : ""
                          }`}
                          id={tabId}
                          data-bs-toggle="tab"
                          data-bs-target={`#${tabPane}`}
                          type="button"
                          role="tab"
                          aria-controls={tabPane}
                          aria-selected="true"
                          onClick={() => handleTabChange(tabIndex)}
                        >
                          {tabName}
                        </button>
                      </li>
                    );
                  })}
                </ul>

                <div className="tab-content" id="myTabContent">
                  {parsedTabIndex === 0 && <PersonalDetails />}
                  {parsedTabIndex === 1 && <ProfessionalDetails />}
                  {parsedTabIndex === 2 && <VoterDetails />}
                  {parsedTabIndex === 3 && <DonorDetails />}
                  {parsedTabIndex === 4 && <ScriptDetails />}
                  {parsedTabIndex === 5 && <IntegrationsDetails />}
                  {parsedTabIndex === 6 && <CallLogs />}
                  {parsedTabIndex === 7 && <SecurityDetails />}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Profile;
