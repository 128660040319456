import InfoIconDanger from "img/svg/InfoIconDanger";
import InfoIconSecondary from "img/svg/InfoIconSecondary";
import TrashIcon from "img/svg/TrashIcon";
import CheckSuccess from "img/svg/checkSuccess";
import FileIcon from "img/svg/fileIcon";
import HelpIcon from "img/svg/helpIcon";
import ThreeDots from "img/svg/threeDots";
import React, { useEffect } from "react";
import { Tooltip } from "bootstrap";
import { Link } from "react-router-dom";
import AlertBanner from "templates/components/AlertBanner";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "components/common/Spinner";
import DonorEmail from "./DonorEmail";

const DonorDetails = () => {
  const notify = () => {
    // toast("Wow so easy!");

    toast.error("Deleted Successfully !", {
      className: "alert alert-success",
      position: toast.POSITION.TOP_RIGHT,
      theme: "light",
      hideProgressBar: true,
      autoClose: 5000,
      icon: <CheckSuccess />,
    });
  };

  useEffect(() => {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  //tooltip
  const VOICEMAIL_TOOLTIP = `Tip: Leaving a voicemail is most effective towards the end of the campaign.`;

  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <div className="mt-5">
            <div className="settings">
              <div className="settings-header d-flex justify-content-between">
                <div className="">
                  <h4> Fundraise </h4>
                  <p className="body-4 text-muted">
                    {" "}
                    View your fundraise details{" "}
                  </p>
                </div>
              </div>

              <div className="settings-body">
                <div className="mt-4">
                  <AlertBanner />
                </div>

                <div className="voterDetail-info">
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7"> Fundraising website </span>
                    </div>
                    <div className="col-md-8">
                      <div className="row justify-content-end">
                        <div className="col-md-8">
                          <span className="body-4 text-muted">
                            {" "}
                            Not Provided{" "}
                          </span>

                          {/* <input type="text" className="form-control" placeholder="Enter phone no."/>
                          <div id="emailHelp" className="form-text text-danger fs-8"> <span className='me-2'> <InfoIconDanger /> </span>  Phone no. should be 10 digit </div> */}
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-end">
                            <button className="btn btn-link btn-xs px-1 py-0">
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7"> Fund raised </span>
                    </div>
                    <div className="col-md-8">
                      <div className="row justify-content-end">
                        <div className="col-md-8">
                          {/* <span className="body-4 text-muted"> Not Provided </span> */}
                          <span className="h4"> $11,871 </span>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-end">
                            <button className="btn btn-link btn-xs px-1 py-0">
                              Edit
                            </button>

                            {/* if not available */}
                            {/* <button className="btn btn-link btn-xs px-1 py-0">
                            Add
                          </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7"> Fundraising goal </span>
                    </div>
                    <div className="col-md-8">
                      <div className="row justify-content-end">
                        <div className="col-md-8">
                          {/* <span className="body-4 text-muted"> Not Provided </span> */}
                          <span className="h4"> $13,871 </span>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-end">
                            <button className="btn btn-link btn-xs px-1 py-0" data-bs-toggle="modal" data-bs-target="#editFundraisingModal">
                              Edit
                            </button>
                            {/* if not available */}
                            {/* <button className="btn btn-link btn-xs px-1 py-0">
                            Add
                          </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />

                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7"> Donor list </span>
                    </div>
                    <div className="col-md-8">
                      <div className="uploadedCard mb-0 p-0 border-0">
                        <div className="uploadedCard-icon">
                          <div className="iconBox">
                            <FileIcon />
                          </div>

                          <div className="iconInfo">
                            <span className="iconInfo-title">
                              {" "}
                              donor-list.pdf{" "}
                            </span>
                            <p className="iconInfo-desc">
                              {" "}
                              1.5 MB, 6/23/23 at 11:45 am{" "}
                            </p>
                          </div>
                        </div>
                        <div className="uploadedCard-info">
                          <button className="btn btn-link rounded-pill btn-xs">
                            Download
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />
                </div>
              </div>
            </div>
          </div>

          {/* voicemail */}
          <div className="mt-5">
            <div className="settings">
              <div className="settings-header d-flex justify-content-between">
                <div className="">
                  <div className="d-flex gap-2">
                    <h4> Voicemail </h4>
                    <Link
                      to=""
                      data-bs-html="true"
                      data-bs-custom-class="custom-tooltip"
                      className="text-left"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      data-bs-title={VOICEMAIL_TOOLTIP}
                    >
                      <HelpIcon />
                    </Link>
                  </div>

                  <p className="body-4 text-muted">
                    Upload or change the voicemail to send the donors in a call.
                  </p>
                </div>
              </div>

              <div className="settings-body">
                <div className="voterDetail-info">
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7"> Voicemail </span>
                    </div>
                    <div className="col-md-8">
                      <div className="uploadedCard">
                        <div className="uploadedCard-icon">
                          <div className="iconInfo">
                            <span className="iconInfo-title text-muted">
                              mpeg, mp3, WAV{" "}
                              <span className="ms-2 dot bg-neutral-n3"> </span>{" "}
                              2MB max
                            </span>
                          </div>
                        </div>
                        <div className="uploadedCard-info file-btn">
                          <button className="btn btn-info rounded-pill">
                            {" "}
                            Upload
                            <input type="file" name="file" />
                          </button>
                        </div>
                      </div>

                      {/* File Uploaded */}

                      <div className="uploadedCard">
                        <div className="uploadedCard-icon">
                          <div className="iconBox">
                            <FileIcon />
                          </div>

                          <div className="iconInfo">
                            <span className="iconInfo-title">
                              {" "}
                              voter-call.mp3{" "}
                            </span>
                            <p className="iconInfo-desc"> 2.5 MB, 0:40s </p>
                            <span className="iconInfo-status">
                              {" "}
                              {/* <CheckSuccess /> File uploaded{" "} */}
                              {/* <InfoIconDanger /> <span className="text-danger"> <strong> Error: </strong> invalid format </span> */}
                              <InfoIconDanger />{" "}
                              <span className="text-danger">
                                {" "}
                                <strong> Error: </strong> Upload time out{" "}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="uploadedCard-info file-btn">
                          <button className="btn btn-link rounded-pill file-btn text-primary">
                            {" "}
                            Change
                            <input type="file" name="file" />
                          </button>
                          <button className="btn btn-link no-hover px-1 text-primary">
                            <TrashIcon />
                          </button>
                          <div className="action">
                            <Link
                              className="px-3 py-2"
                              to=""
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <ThreeDots />
                            </Link>

                            <div className="dropdown-menu">
                              <div className="dropdown-group">
                                <ul className="dropdown-body list-unstyled mb-0">
                                  <li>
                                    <Link to=""> Download voicemail</Link>
                                  </li>
                                  <li>
                                    <Link
                                      to=""
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteDonorVoicemailModal"
                                    >
                                      {" "}
                                      Delete voicemail{" "}
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />
                </div>
              </div>
            </div>
          </div>

          {/* Actblue Integration */}

          <div className="mt-5">
            <div className="settings">
              <div className="settings-header d-flex justify-content-between">
                <div className="">
                  <h4> Integrate Actblue </h4>
                  <p className="body-4 text-muted">
                    Send ActBlue integration to sync the funds raised. The
                    integration it is expected to take approximately 2 days.
                  </p>
                </div>
              </div>

              <div className="settings-body">
                <div className="voterDetail-info">
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7"> Actblue </span>
                    </div>
                    <div className="col-md-8">
                      <div className="row justify-content-end">
                        <div className="col-md-8">
                          <span className="body-4 text-muted">  Integration request not sent </span>
                          <div className="d-flex flex-column">
                            <div>
                              <span className="body-4">
                                {" "}
                                Integration request sent{" "}
                              </span>
                              
                              <span className="badge bg-warning-w2 rounded-pill px-2 font-regular">
                                {" "}
                                Unlinked{" "}
                              </span>
                            </div>
                            <span className="body-4 text-muted">
                              {" "}
                              Sent on 20 Feb 2024{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-end">
                            <button className="btn btn-link btn-xs px-1 py-0">
                              Send request
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />
                </div>
              </div>
            </div>
          </div>

          {/* Donor email */}
          <div className="mt-5">
            <DonorEmail />
          </div>
        </div>
      </div>

      {/* delete warning Modal  */}
      <div
        className="modal fade"
        id="deleteDonorVoicemailModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../img/leave.png")}
                width={66}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3">Delete voicemail?</h2>
              <p>
                Voicemail will not be available. You will no longer be able to
                send the voicemail to the voters in a call.
              </p>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-link rounded-pill btn-xs"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>

              <button
                className="btn btn-primary rounded-pill btn-xs"
                onClick={notify}
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      </div>

       {/* edit fundraising goal Modal */}
       <div
          className="modal fade"
          id="editFundraisingModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
              
              <div className="modal-body text-center px-4">

              <img
                src={require("../../../img/leave.png")}
                width={66}
                height={56}
                alt="leave alert"
              />
              <h2 className="mt-3">Do you want to edit “Fundraising goal”?</h2>
              <p>
              By editing this, the current budget will be updated and replaced by new one.
              </p>
               
              </div>

              <div className="modal-footer">
                <Link
                  to=""
                  className="btn btn-link text-primary btn-xs rounded-pill"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </Link>
                {/* remove button disabled class after spinner goes off */}
                <Link
                  to=""
                  className="btn btn-primary rounded-pill btn-xs px-6 disabled"
                >
                  
                  <Spinner />
                  Yes, edit
                </Link>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default DonorDetails;
