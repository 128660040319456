import InfoIconSecondary from "img/svg/InfoIconSecondary";
import FileIcon from "img/svg/fileIcon";
import React, { useEffect } from "react";
import useDonorDetails from "./useDonorDetails";
import {
  dateFormatter,
  formatFileSize,
} from "../professional-details/useProfessionalDetils";
import { useForm } from "react-hook-form";
import InfoIconDanger from "img/svg/InfoIconDanger";
import toastNotify from "utils/toastNotify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import AlertBanner from "templates/components/AlertBanner";
import useCheckDashboardCompletedStatus from "utils/useDashboardCompletedStatus";
import VoiceMailUpload from "../voter-details/components/VoiceMailUpload";

const DonorDetails = () => {
  const {
    editable,
    setEditable,
    donorFileData,
    donorData,
    postDonorsSubmit,
    updateDonorsSubmit,
    sendActBlueIntegrationEmail,
    actBlueStatus,
    isRequetSending,
    userInfo,
  } = useDonorDetails();

  const { userData } = useCheckDashboardCompletedStatus();

  const form = useForm();
  const {
    register,
    setValue,
    reset,
    getValues,
    handleSubmit,
    formState: { errors },
  } = form;

  const handleDownload = (url: string | null | undefined) =>
    url ? window.open(url, "_blank") : null;

  const handleSubmitForm = (data: any) => {
    const { donationUrl, fundRaised, fundraisingGoal } = data;

    const fundRaiseNull = fundRaised === "" ? null : fundRaised;
    const fundRaisingGoalNull = fundraisingGoal === "" ? null : fundraisingGoal;

    if (data.action === "saveFunraisingWebsiteAction") {
      if (
        !donationUrl.startsWith("http://") &&
        !donationUrl.startsWith("https://")
      ) {
        toastNotify("error", "Wrong format! Add http or https protocol");
        return;
      }
      if (!donorData?.id) {
        postDonorsSubmit({ donationUrl });
        return;
      }
      updateDonorsSubmit({ donationUrl });
    }
    if (data.action === "saveFundRaiseAction") {
      if (isNaN(fundRaiseNull) || fundRaiseNull < 0) {
        toastNotify("error", "Fund raised must be a number");
        return;
      }
      if (!donorData?.id) {
        postDonorsSubmit({ fundRaised: fundRaiseNull });
        return;
      }
      updateDonorsSubmit({ fundRaised: fundRaiseNull });
    }
    if (data.action === "saveFundRaisingGoalAction") {
      if (isNaN(fundRaisingGoalNull) || fundRaisingGoalNull < 0) {
        toastNotify("error", "Fund raising Goal must be a number");
        return;
      }

      if (!donorData?.id) {
        postDonorsSubmit({ fundraisingGoal: fundRaisingGoalNull });
        return;
      }
      updateDonorsSubmit({ fundraisingGoal: fundRaisingGoalNull });
    }
  };

  useEffect(() => {
    if (donorData) {
      Object.entries(donorData).forEach(([key, value]: any) => {
        setValue(key, value);
      });
    }
  }, [donorData]);

  return (
    <div className="row mt-5">
      <div className="col-md-8">
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="settings">
            <div className="settings-header d-flex justify-content-between">
              <div className="">
                <h4> Fundraise </h4>
                <p className="body-4 text-muted">
                  {" "}
                  View your fundraise details{" "}
                </p>
              </div>
            </div>

            <div className="settings-body">
              {userData?.isDashboardSetupCompleted === false ? (
                <div className="mt-4">
                  <AlertBanner />
                </div>
              ) : (
                <div className="voterDetail-info">
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7"> Donation link </span>
                    </div>
                    <div className="col-md-8">
                      <div className="row justify-content-end">
                        <div className="col-md-8">
                          {editable === "website" ? (
                            <>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Add link"
                                {...register("donationUrl")}
                              />
                              {errors?.donationUrl?.message && (
                                <div
                                  id="emailHelp"
                                  className="form-text text-danger fs-8"
                                >
                                  {" "}
                                  <span className="me-2">
                                    {" "}
                                    <InfoIconDanger />{" "}
                                  </span>{" "}
                                  {errors?.donationUrl?.message as string}
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {donorData?.donationUrl ? (
                                <Link
                                  to={donorData?.donationUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {donorData?.donationUrl}{" "}
                                </Link>
                              ) : (
                                <span className="body-4 text-muted">
                                  {" "}
                                  Not Provided
                                </span>
                              )}
                            </>
                          )}
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-end">
                            {editable === "website" ? (
                              <>
                                <button
                                  className="btn btn-link btn-xs px-1 py-0 d-inline-block"
                                  onClick={() => {
                                    setEditable(null);
                                    setValue("action", null);
                                    setValue(
                                      "donationUrl",
                                      donorData?.donationUrl ?? ""
                                    );
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn btn-link btn-xs px-1 py-0 ms-3 d-inline-block"
                                  type="submit"
                                  onClick={() => {
                                    setValue(
                                      "action",
                                      "saveFunraisingWebsiteAction"
                                    );
                                  }}
                                >
                                  Save
                                </button>
                              </>
                            ) : (
                              <button
                                className="btn btn-link btn-xs px-1 py-0"
                                onClick={() => {
                                  setEditable("website");
                                  setValue("action", null);
                                }}
                              >
                                {donorData?.donationUrl ? "Edit" : "Add"}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7"> Fund raised </span>
                    </div>
                    <div className="col-md-8">
                      <div className="row justify-content-end">
                        <div className="col-md-8">
                          {editable === "fundraise" ? (
                            <>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Vote Goal"
                                {...register("fundRaised")}
                              />
                              {errors?.fundRaised?.message && (
                                <div
                                  id="emailHelp"
                                  className="form-text text-danger fs-8"
                                >
                                  {" "}
                                  <span className="me-2">
                                    {" "}
                                    <InfoIconDanger />{" "}
                                  </span>{" "}
                                  {errors?.fundRaised?.message as string}
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {donorData?.fundRaised ? (
                                <span className="h4">
                                  {" "}
                                  ${donorData?.fundRaised}
                                </span>
                              ) : (
                                <span className="body-4 text-muted">
                                  {" "}
                                  Not Provided
                                </span>
                              )}
                            </>
                          )}
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-end">
                            {editable === "fundraise" ? (
                              <>
                                <button
                                  className="btn btn-link btn-xs px-1 py-0 d-inline-block"
                                  onClick={() => {
                                    setEditable(null);
                                    setValue("action", null);
                                    setValue(
                                      "fundRaised",
                                      donorData?.fundRaised
                                    );
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn btn-link btn-xs px-1 py-0 ms-3 d-inline-block"
                                  type="submit"
                                  onClick={() => {
                                    setValue("action", "saveFundRaiseAction");
                                  }}
                                >
                                  Save
                                </button>
                              </>
                            ) : (
                              <button
                                className="btn btn-link btn-xs px-1 py-0"
                                onClick={() => {
                                  setEditable("fundraise");
                                  setValue("action", null);
                                }}
                              >
                                {donorData?.fundRaised ? "Edit" : "Add"}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7"> Fundraising goal </span>
                      <div className="d-flex gap-2">
                        <InfoIconSecondary />
                        <span className="body-4 text-muted mb-0">
                          {" "}
                         It cannot be changed later; confirm amount before adding.
                        </span>
                      </div>
                    </div>

                    <div className="col-md-8">
                      <div className="row justify-content-end">
                        <div className="col-md-8">
                          {editable === "fundRaiseGoal" ? (
                            <>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Vote Goal"
                                {...register("fundraisingGoal")}
                              />
                              {errors?.fundraisingGoal?.message && (
                                <div
                                  id="emailHelp"
                                  className="form-text text-danger fs-8"
                                >
                                  {" "}
                                  <span className="me-2">
                                    {" "}
                                    <InfoIconDanger />{" "}
                                  </span>{" "}
                                  {errors?.fundraisingGoal?.message as string}
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {donorData?.fundraisingGoal ? (
                                <span className="h4">
                                  {" "}
                                  ${donorData?.fundraisingGoal}
                                </span>
                              ) : (
                                <span className="body-4 text-muted">
                                  {" "}
                                  Not Provided
                                </span>
                              )}
                            </>
                          )}
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-end">
                            {editable === "fundRaiseGoal" ? (
                              <>
                                <button
                                  className="btn btn-link btn-xs px-1 py-0 d-inline-block"
                                  onClick={() => {
                                    setEditable(null);
                                    setValue("action", null);
                                    setValue(
                                      "fundraisingGoal",
                                      donorData?.fundraisingGoal
                                    );
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn btn-link btn-xs px-1 py-0 ms-3 d-inline-block"
                                  type="submit"
                                  onClick={() => {
                                    setValue(
                                      "action",
                                      "saveFundRaisingGoalAction"
                                    );
                                  }}
                                >
                                  Save
                                </button>
                              </>
                            ) : (
                              <button
                                className="btn btn-link btn-xs px-1 py-0"
                                onClick={() => {
                                  setEditable("fundRaiseGoal");
                                  setValue("action", null);
                                }}
                              >
                                {!donorData?.fundraisingGoal && "Add"}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />

                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7"> Donor list </span>
                    </div>
                    <div className="col-md-8">
                      {donorFileData ? (
                        <div className="uploadedCard mb-0 p-0 border-0">
                          <div className="uploadedCard-icon">
                            <div className="iconBox">
                              <FileIcon />
                            </div>

                            <div className="iconInfo">
                              <span className="iconInfo-title">
                                {" "}
                                {donorFileData?.fileName}{" "}
                              </span>
                              <p className="iconInfo-desc">
                                {" "}
                                {/* 1.5 MB, 6/23/23 at 11:45 am{" "} */}
                                {formatFileSize(donorFileData?.size)},{" "}
                                {dateFormatter(donorFileData?.updatedAt)}
                              </p>
                            </div>
                          </div>
                          <div className="uploadedCard-info">
                            <button
                              className="btn btn-link rounded-pill btn-xs"
                              onClick={() => handleDownload(donorFileData?.url)}
                            >
                              Download
                            </button>
                          </div>
                        </div>
                      ) : (
                        <span className="body-4 text-muted">
                          {" "}
                          Not Provided{" "}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Actblue integration  */}
          <div className="mt-5">
            <div className="settings">
              <div className="settings-header d-flex justify-content-between">
                <div className="">
                  <h4> Integrate Actblue </h4>
                  <p className="body-4 text-muted">
                    Send ActBlue integration to sync the funds raised. The
                    integration it is expected to take approximately 2 days.
                  </p>
                </div>
              </div>

              <div className="settings-body">
                <div className="voterDetail-info">
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7"> Actblue </span>
                    </div>
                    <div className="col-md-8">
                      <div className="row justify-content-end">
                        <div className="col-md-8">
                          {!actBlueStatus?.actBlueIntegrationStatus ? (
                            <span className="body-4 text-muted">
                              Integration request not sent
                            </span>
                          ) : (
                            <div className="d-flex flex-column">
                              <div>
                                {actBlueStatus?.actBlueIntegrationStatus ===
                                "Not Linked" ? (
                                  <>
                                    <span className="body-4">
                                      Integration request sent{" "}
                                    </span>
                                    <span className="badge bg-warning-w2 rounded-pill px-2 font-regular">
                                      Not Linked
                                    </span>
                                  </>
                                ) : null}
                                {actBlueStatus?.actBlueIntegrationStatus ===
                                "Linked" ? (
                                  <>
                                    <span className="body-4">Integrated </span>
                                    <span className="badge bg-success-s2 rounded-pill px-2 font-regular">
                                      Linked
                                    </span>
                                  </>
                                ) : null}
                              </div>
                              {/* <span className="body-4 text-muted">
                                Sent on 20 Feb 2024
                              </span> */}
                            </div>
                          )}
                        </div>
                        {actBlueStatus?.actBlueIntegrationStatus !==
                        "Linked" ? (
                          <div className="col-md-4">
                            <div className="d-flex justify-content-end">
                              <button
                                className={`btn btn-link btn-xs px-1 py-0 `}
                                onClick={() => sendActBlueIntegrationEmail()}
                              >
                                {isRequetSending
                                  ? "Sending..."
                                  : "Send request"}
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />
                </div>
              </div>
            </div>
          </div>
        </form>
        <VoiceMailUpload
          candidateElectionId={userInfo?.candidate?.elections?.[0]?.id || 0}
          sectionType="fundraise" // 'voter' or 'fundraise'
        />
      </div>
    </div>
  );
};

export default DonorDetails;
