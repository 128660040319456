import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "index";
import { useState } from "react";
import CreateAuthAxiosInstance from "utils/authAxios";
import toastNotify from "utils/toastNotify";
import { globalQueryConfig } from "utils/reactQuery";
import { FILE_OWNER } from "../../../../constant/file";

const useDonorDetails = () => {
  const authAxios = CreateAuthAxiosInstance();
  const [editable, setEditable] = useState<string | null>(null);
  const [isRequetSending, setIsRequestSending] = useState(false);

  async function fetchDonorData() {
    const response = await authAxios.get(
      "api/v1/profile-setting/candidate-details"
    );
    return response.data;
  }

  const { data: donorData } = useQuery({
    queryKey: ["donorData"],
    queryFn: fetchDonorData,
    ...globalQueryConfig,
  });

  async function postDonorData(data: any) {
    const response = await authAxios.post(
      "api/v1/profile-setting/candidate-details",
      data
    );
    return response.data;
  }

  const { mutate: postDonorsSubmit } = useMutation({
    mutationFn: postDonorData,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["donorData"],
      });
      fetchDonorData();
      toastNotify("success", "Successfully Created!");
      reset();
    },
    onError: (error: any) => {
      console.log("on error", error);
      toastNotify("error", error?.response?.data?.message);
    },
  });

  async function updateDonorData(data: any) {
    const response = await authAxios.patch(
      "api/v1/profile-setting/candidate-details",
      data
    );
    return response.data;
  }

  const { mutate: updateDonorsSubmit } = useMutation({
    mutationFn: updateDonorData,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["donorData"],
      });
      fetchDonorData();
      toastNotify("success", "Changes saved!");
      reset();
    },
    onError: (error: any) => {
      console.log("on error", error);
      toastNotify("error", error?.response?.data?.message);
    },
  });

  async function fetchDonorFile() {
    const { data } = await authAxios.get("api/v1/file/file-type/2");
    const adminDonorFile = data.find(
      (file: any) => file.createdBy === FILE_OWNER.ADMIN
    );
    return adminDonorFile;
  }

  const { data: donorFileData } = useQuery({
    queryKey: ["fetch-donorFile"],
    queryFn: fetchDonorFile,
    ...globalQueryConfig,
  });

  async function fetchActBlueStatus() {
    const { data } = await authAxios.get("api/v1/actblue/actblue-status");
    return data;
  }

  const actBlueStatusQuery = useQuery({
    queryKey: ["fetch-actblue-status"],
    queryFn: fetchActBlueStatus,
    ...globalQueryConfig,
  });

  const { data: actBlueStatus } = actBlueStatusQuery;

  async function sendEmail() {
    const response = await authAxios.post("api/v1/email/actblue-integration");
    return response.data;
  }

  const { mutate: sendActBlueIntegrationEmail } = useMutation({
    mutationFn: sendEmail,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["actBlueIntegration"],
      });
      actBlueStatusQuery.refetch();
      toastNotify("success", "Email sent!");
    },
    onError: (error: any) => {
      console.log("on error", error);
      toastNotify("error", error?.response?.data?.message);
    },
    onMutate: () => {
      setIsRequestSending(true);
    },
    onSettled: () => {
      setIsRequestSending(false);
    },
  });

  const reset = () => {
    setEditable(null);
  };

  const {
    data: userInfo
  } = useQuery({
    queryKey: ['candidateDetailVoiceMailDonor', donorData?.id],
    queryFn: () => authAxios.get(`api/v1/user/info/${donorData?.id}`).then(data => data?.data),
    refetchOnWindowFocus: false,
    enabled: !!(donorData?.id)
  })

  return {
    editable,
    setEditable,
    donorFileData,
    donorData,
    postDonorsSubmit,
    updateDonorsSubmit,
    sendActBlueIntegrationEmail,
    actBlueStatus,
    isRequetSending,
    userInfo
  };
};

export default useDonorDetails;
