import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { IBudgetFileDetails, IBudgetFileType } from 'components/pages/dashboard/account-setup/Budget'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { dateFormatter, formatFileSize } from 'utils/uploadFile'
import { isValidFileType } from '../CheckValidFile'
import { fileSizeLimit } from 'utils/budgetFileSizeLimit'
import CreateAuthAxiosInstance from 'utils/authAxios'
import InfoIconDanger from 'img/svg/InfoIconDanger'
import Spinner from 'components/common/Spinner'
import FileUpload from './components/FileUpload'
import InitialFile from './components/InitialFile'
import NoFile from './components/NoFile'

interface IUploadModal {
  handleTemplateDownload: () => Promise<void>
  budgetDetailsFromApi: IBudgetFileDetails
  electionId: string | null
  budgetFileType: IBudgetFileType
}

export interface IValidationResponse {
  candidateId: number
  fileName: string | null
  id: number
  isArchived: boolean
  createdAt: string | null
  updatedAt: string | null
  deletedAt: string | null
  status: string
}

const UploadModal = ({
  handleTemplateDownload,
  budgetDetailsFromApi,
  electionId,
  budgetFileType
}: IUploadModal) => {
  const authAxios = CreateAuthAxiosInstance();
  const queryClient = useQueryClient()
  const currentDate = new Date();

  const [file, setFile] = useState<File | null>(null);
  const [parsedBudgetId, setParsedBudgetId] = useState<number | null>(null)
  const [timeZone, setTimeZone] = useState("");
  const [error, setError] = useState<string | null>(null);
  const closeRef = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(tz);
  }, []);

  useEffect(() => {
    if (parsedBudgetId) setParsedBudgetId(null)
  }, [file])

  const {
    mutate: validateBudgetFile,
    isPending: isPendingFileValidation
  } =
    useMutation({
      mutationFn: budgetFileValidationFn,
      onSuccess: handleBudgetFileValidationSuccess,
      onError: handleBudgetFileValidationError
    });

  async function budgetFileValidationFn({ timeZone, file, }: {
    timeZone: string;
    file: FormData;
  }) {
    const response = await authAxios.post("api/v1/budgets/validate", file, {
      headers: {
        "Content-Type": "multipart/form-data",
        "time-zone": timeZone,
      },
    });
    return response?.data;
  }

  function handleBudgetFileValidationSuccess(data: IValidationResponse) {
    if (data?.id)
      setParsedBudgetId(data.id)
  }

  function handleBudgetFileValidationError(error: unknown) {
    if (axios.isAxiosError(error)) {
      setError(error?.response?.data?.message);
    }
  }


  const {
    mutate: uploadBudgetFile,
    isPending: isFileUploadPending,
    isSuccess: isFileUploadSuccess
  } =
    useMutation({
      mutationFn: handleBudgetFileUpload,
      onSuccess: handleUploadOnSuccessEvent,
      onError: handleUploadOnErrorEvent
    });

  async function handleBudgetFileUpload({ timeZone, file, }: {
    timeZone: string;
    file: FormData;
  }) {
    const response = await authAxios.post(`api/v1/budgets/upload/${parsedBudgetId}`, file, {
      headers: {
        "Content-Type": "multipart/form-data",
        "time-zone": timeZone,
      },
    });
    return response?.data;
  }

  async function handleUploadOnSuccessEvent() {
    closeRef?.current?.click()
    await queryClient.invalidateQueries({ queryKey: ["budgetFileData"], });
    await queryClient.invalidateQueries({ queryKey: ["budget", "detail"], });

    setError(null);
  }

  function handleUploadOnErrorEvent(error: unknown) {
    if (axios.isAxiosError(error)) {
      setError(error?.response?.data?.message);
    }
  }


  const {
    mutate: mutateDeleteBudgetFile
  } = useMutation({
    mutationFn: () => authAxios.delete(`api/v1/file/${budgetDetailsFromApi?.id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["budgetFileData"] });
      setFile(null);
    },
  });

  const handleInputFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(null)
    if (!(e.target.files?.length && e.target.files?.[0])) return;

    const tempFile = e.target.files[0];
    setFile(tempFile);

    handleFileValidation(tempFile)
  };

  const handleFileValidation = (validationFile: File) => {
    if (validationFile && isValidFileType(validationFile) && validationFile.size <= fileSizeLimit && electionId && budgetFileType?.id) {
      const formData = new FormData();

      formData.append("fileTypeId", String(budgetFileType?.id));
      formData.append("file", validationFile);

      validateBudgetFile({ timeZone, file: formData });
    }
  }
  const handleFileUpload = () => {
    if (file && isValidFileType(file) && file.size <= fileSizeLimit && electionId && budgetFileType?.id) {
      const formData = new FormData();

      formData.append("fileTypeId", String(budgetFileType?.id));
      formData.append("file", file);

      uploadBudgetFile({ timeZone, file: formData });
    }
  }

  function generateFileNameOrError() {
    if (!file) return;

    if (isValidFileType(file) && file.size <= fileSizeLimit && !error) {
      return (
        <p className="iconInfo-desc">
          {formatFileSize(file?.size)},
          {dateFormatter(currentDate.toISOString())}
        </p>
      );
    }

    const errorMsg: string[] = [];
    if (file.size > fileSizeLimit) {
      errorMsg.push(" File size exceeds 20 KB.");
    }
    if (!isValidFileType(file)) {
      errorMsg.push(
        " File type not valid. Please enter a file of format xlsx."
      );
    }
    if (error && Array.isArray(error)) {
      error.forEach((error: string) => errorMsg.push(error));
    }

    return (
      <div className={`${errorMsg?.length > 1 && "messageWrapper"}`}>
        <span className="iconInfo-status">
          <div className="text-danger">
            {!!errorMsg?.length &&
              errorMsg.map((msg, index) => {
                return (
                  <span className="d-block" key={msg}>
                    <InfoIconDanger />
                    <strong> Error {errorMsg?.length > 1 && index + 1}</strong>:
                    {msg}
                  </span>
                );
              })}
          </div>
        </span>
      </div>
    );
  }


  return (
    <div
      className="modal fade"
      id="uploadModal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-1">
            <h5 className="modal-title body-2 mb-0">Add budget</h5>
            <button
              type="button"
              className="btn-close p-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setFile(null)}
            >
              <span className="icon-close text-n1 h2"></span>
            </button>
          </div>
          <div className="modal-body">
            <form action="">
              <div className="mb-3">
                <label className="custom-label">File upload</label>
                <p className="body-4 text-muted mb-1">
                  Upload a file of any xlsx format containing information
                  about budget of your campaign.
                </p>
                <p className="body-4 text-muted mb-1">
                  (You can &nbsp;
                  <Link
                    to=""
                    onClick={handleTemplateDownload}
                  >
                    download our template
                  </Link>
                  )
                </p>

                {/* after upload */}
                {file ? (
                  <FileUpload
                    file={file}
                    setFile={setFile}
                    generateFileNameOrError={generateFileNameOrError}
                    isFileUploadPending={isFileUploadPending}
                    isFileUploadSuccess={isFileUploadSuccess}
                    handleInputFileChange={handleInputFileChange}
                  />
                ) : (
                  budgetDetailsFromApi ? (
                    <InitialFile
                      budgetDetailsFromApi={budgetDetailsFromApi}
                      handleInputFileChange={handleInputFileChange}
                      mutateDeleteBudgetFile={mutateDeleteBudgetFile}
                    />
                  ) : (
                    <>
                      {/* before upload */}
                      <NoFile handleInputFileChange={handleInputFileChange} />
                    </>
                  )
                )}
              </div>
            </form>
          </div>

          <div className="modal-footer border-1">
            <Link
              to=""
              ref={closeRef}
              className="btn btn-link rounded-pill text-primary btn-xs"
              data-bs-dismiss="modal"
              onClick={() => setFile(null)}
            >
              Cancel
            </Link>
            <Link
              to=""
              className={`btn btn-primary rounded-pill btn-xs px-6 ${(!file || !isValidFileType(file) || file.size > fileSizeLimit || error || isPendingFileValidation || isFileUploadPending) && "disabled"}`}
              onClick={handleFileUpload}
            >
              {(isPendingFileValidation || isFileUploadPending) && <Spinner />}
              {isPendingFileValidation ? 'Processing...' : 'Upload'}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}



export default React.memo(UploadModal)