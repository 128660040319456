import CustomTooltip from "components/common/CustomTooltip";
import HelpIcon from "img/svg/helpIcon";
import React, { useState } from "react";
import CreateAuthAxiosInstance from "utils/authAxios";
import { getDateForBudgetPage } from "utils/formatDate";
import { IMonthlyBudget } from "../BudgetDetailType";
import Spinner from "components/common/Spinner";
import {
  IBudgetFileDetails,
  IBudgetFileType,
} from "components/pages/dashboard/account-setup/Budget";
import { ELECTION_ID } from "components/pages/dashboard/Dashboard";
import EditModal from "../../common/EditModal";
import TopBanner from "../../common/TopBanner";
import { IFundraisingData } from "../../budget-overview/BudgetOverview";
import UploadModal from "../../common/upload-file/UploadModal";

interface IIntroSection {
  monthColumnData: IMonthlyBudget[];
  budgetDetailsFromApi: IBudgetFileDetails;
  funraisingData: IFundraisingData;
  budgetFileType: IBudgetFileType;
}

const BUDGET_DETAILS_TOOLTIP = `Track your monthly expenses and income sources by editing the cells directly. The budget timeframe covers the period from before signup to one month after the election.`;

const IntroSection = ({
  monthColumnData,
  budgetDetailsFromApi,
  funraisingData,
  budgetFileType,
}: IIntroSection) => {
  const electionId = localStorage.getItem(ELECTION_ID);
  const authAxios = CreateAuthAxiosInstance();

  const [isExcelDataLoading, setIsExcelDataLoading] = useState(false);

  const handleDownload = async () => {
    setIsExcelDataLoading(true);
    try {
      const response = await authAxios("/api/v1/budgets/excel-export", {
        responseType: "blob", // This tells axios to return data as a Blob
      });
      const blobUrl = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", "budget-details.xlsx"); // Setting the correct file extension
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(blobUrl);
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error("Failed to download the file:", error);
    } finally {
      setIsExcelDataLoading(false);
    }
  };

  const handleTemplateDownload = async () => {
    try {
      const response = await authAxios("/api/v1/budgets/import-template", {
        responseType: "blob", // This tells axios to return data as a Blob
      });
      const blobUrl = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", "budget_template.xlsx");
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(blobUrl);
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error("Failed to download the file:", error);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <TopBanner
            funraisingData={funraisingData}
            budgetDetailsFromApi={budgetDetailsFromApi}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="section-box">
            <div className="row align-items-center">
              <div className="col-md-7">
                <div className="d-flex gap-2 flex-column">
                  <div className="d-flex gap-2">
                    <h3 className="section-box-title">Budget details</h3>
                    <CustomTooltip content={BUDGET_DETAILS_TOOLTIP}>
                      <HelpIcon />
                    </CustomTooltip>
                  </div>
                  <span className="body-3 text-muted">
                    View or edit your projected budget information for&nbsp;
                    {getDateForBudgetPage(monthColumnData?.[0]?.date, true)?.formattedDate}
                    -&nbsp;
                    {getDateForBudgetPage(monthColumnData?.[monthColumnData?.length - 1]?.date, true)?.formattedDate}
                    .
                  </span>
                </div>
              </div>
              <div className="col-md-5 text-end">
                <button
                  onClick={handleDownload}
                  className="btn btn-info rounded-pill btn-xs px-4"
                  disabled={isExcelDataLoading}
                >
                  Export XLSX {isExcelDataLoading && <Spinner />}
                </button>
                {!budgetDetailsFromApi ? (
                  <button
                    className="btn btn-primary rounded-pill btn-xs px-4 ms-3"
                    data-bs-toggle="modal"
                    data-bs-target="#uploadModal"
                  >
                    Upload file
                  </button>
                ) : (
                  <button
                    className="btn btn-primary rounded-pill btn-xs px-4 ms-3"
                    data-bs-toggle="modal"
                    data-bs-target="#editBudgetModal"
                  >
                    Change file
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <UploadModal
          handleTemplateDownload={handleTemplateDownload}
          budgetDetailsFromApi={budgetDetailsFromApi}
          electionId={electionId}
          budgetFileType={budgetFileType}
        />

        <EditModal />
      </div>
    </>
  );
};

export default React.memo(IntroSection);
