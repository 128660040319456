export const formattedDate = (date: string | null | undefined) => {
  if (!date) return;
  const dateObject = new Date(date);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get the month, day, and year from the date object
  const month = monthNames[dateObject.getUTCMonth()];
  const day = dateObject.getUTCDate();
  const year = dateObject.getUTCFullYear();

  const formattedDate = `${month} ${day}, ${year}`;
  return formattedDate;
};

export const getYearMonthDay = (date: string) => {
  const formattedDate = date.split("T")[0];
  return formattedDate;
};

export const getDateTime = (timestamp: Date) => {
  // Format date as "MM/DD/YY"
  const dateFormatter = new Intl.DateTimeFormat("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });
  const formattedDate = dateFormatter.format(timestamp); // "03/11/24"

  // Format time as "HH:MM:SS"
  const timeFormatter = new Intl.DateTimeFormat("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  const formattedTime = timeFormatter.format(timestamp); // "07:23:54"

  return `${formattedDate}, ${formattedTime}`;
};

export const getDate = (timestamp: Date) => {
  // Format date as "MM/DD/YY"
  const dateFormatter = new Intl.DateTimeFormat("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });
  const formattedDate = dateFormatter.format(timestamp); // "03/11/24"
  return formattedDate;
};

// const formatDate = (date: string | null | undefined) => {
//   if (!date) return "-";

//   const monthsName = [
//     "Jan",
//     "Feb",
//     "March",
//     "April",
//     "May",
//     "Jun",
//     "Jul",
//     "Aug",
//     "Sept",
//     "Oct",
//     "Nov",
//     "Dec",
//   ];

//   const finalYear = date.substring(0, 4);
//   const finalMonth = monthsName[Number(date.substring(5, 7)) - 1 ?? 0];
//   const finalDate = date.substring(8, 10);

//   return `${finalMonth} ${finalDate}, ${finalYear}`;
// };

export const getTodayDateInNormalFormat = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`; //yyyy-mm-dd
};

export const getDateForBudgetPage = (
  date: string | null | undefined,
  isTitleInfo?: boolean
) => {
  if (!date) return;

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();

  const year = date.slice(0, 4)
  const month = (parseInt(date.slice(5, 7)) - 1) || 0

  const result = {
    formattedDate: isTitleInfo
      ? `${monthNames[month]} ${year}` //Formt -> Jan 2023
      : `${monthNames[month]} \`${year.slice(-2)}`, //Formt -> Jan `23
    isCurrentMonth: currentMonth === month,
  };

  return result;
};

export const formatBudgetOverviewDate = (
  dateStr: string | null | undefined,
  isTitle?: boolean
) => {
  if (!dateStr) return;

  const dateObj = new Date(dateStr);

    // Use toLocaleString to get the month and year in UTC
    const options: Intl.DateTimeFormatOptions  = { month: 'short', year: 'numeric', timeZone: 'UTC' };
    const monthYear = dateObj.toLocaleString('en-US', options);
    
    // Extract month and year from the formatted string
    const [month, year] = monthYear.split(' ');
    const secondHalfYear = year.slice(-2);
  

  const result = {
    formattedDate: isTitle
      ? `${month} ${year}` // Jan 2023
      : `${month} '${secondHalfYear}`, // Jan `01
    currentMonth:
    dateObj.getUTCMonth() === new Date().getUTCMonth() &&
      dateObj.getUTCFullYear() === new Date().getUTCFullYear(),
  };

  return result;
};


 export function incrementMonth(dateString: string, increaseMonthCount: number): string {
  // Parse the input date string
  let [year, month, day] = dateString.split('-').map(Number);

  // Increment the month by the specified count
  month += increaseMonthCount;

  // Handle the year change if month goes over 12 or less than 1
  while (month > 12) {
      month -= 12;
      year += 1;
  }

  while (month <= 0) {
      month += 12;
      year -= 1;
  }

  // Format month and day to ensure two digits
  let monthString = month.toString().padStart(2, '0');
  let dayString = day.toString().padStart(2, '0');

  // Construct the new date string
  return `${year}-${monthString}-${dayString}`;
}