import InfoIconDanger from "img/svg/InfoIconDanger";
import React from "react";
import { Link } from "react-router-dom";
import Spinner from "templates/components/Spinner";
import { ToastContainer, toast } from 'react-toastify';
import CheckSuccess from "img/svg/checkSuccess";

const BudgetContent = () => {
  const notify = () => {
    // toast("Wow so easy!");

    toast.success("Budget reset successfully", {
      className: 'alert alert-success',
      position: toast.POSITION.TOP_RIGHT,
      theme:"light",
      hideProgressBar:true,
      autoClose: 50000,
      icon: <CheckSuccess />
    });
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="mt-5">
            <div className="settings">
            <div className="settings-header d-flex justify-content-between align-items-end">
                <div className="">
                  <h4> Budget </h4>
                  <p className="body-4 text-muted">
                    {" "}
                    Activate a budget mode that you want to display in budget details.{" "}
                  </p>
                </div>

                {/* clear all budget */}

                <button
                  className="btn btn-info rounded-pill btn-xs px-4"
                 data-bs-toggle="modal"
                  data-bs-target="#resetValueModal"
                >
                  {" "}
                  Reset budget{" "}
                </button>
              </div>

              <div className="settings-body">
                <div className="passwordChange-info">
                  <hr className="mt-3 mb-3" />
                  <div className="row">
                    <div className="col-md-4">
                      <span className="body-2 fs-7"> Budget mode </span>
                    </div>
                    <div className="col-md-8">
                      {/* add class .disabled according to the case */}
                      <div className="row justify-content-end groupField mb-4">
                        <div className="col-md-8">
                         
                          <div className="d-flex flex-column">
                            <div>
                              <span className="body-4">
                                {" "}
                                Start from scratch{" "}
                              </span>
                              <span className="badge bg-success rounded-pill px-2 font-regular">
                                {" "}
                                Active{" "}
                              </span>
                            </div>
                            <span className="body-4 text-muted">
                              {" "}
                              Budget details will be empty initially.  {" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-end">
                            <button
                              disabled
                              className="btn btn-link btn-xs px-1 py-0"
                              data-bs-toggle="modal"
                              data-bs-target="#scratchModal"
                            >
                              Activate
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-end groupField mb-4">
                        <div className="col-md-8">
                        
                          <div className="d-flex flex-column">
                            <div>
                              <span className="body-4">
                                {" "}
                                Reflect according to uploaded file{" "}
                              </span>
                              {/* <span className="badge bg-success rounded-pill px-2 font-regular">
                                {" "}
                                Active{" "}
                              </span> */}
                            </div>
                            <span className="body-4 text-muted">
                              {" "}
                              Upload budget file from budget details to select this budget mode.{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-end">
                            <button
                            
                              className="btn btn-link btn-xs px-1 py-0"
                              data-bs-toggle="modal"
                              data-bs-target="#fileUploadBudgetModal"
                            >
                              Activate
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-end groupField disabled mb-4">
                        <div className="col-md-8">
                         
                          <div className="d-flex flex-column">
                            <div>
                              <span className="body-4">
                                {" "}
                                Reflect according to suggested budget based on fundraising goal{" "}
                              </span>
                              <span className="badge bg-success rounded-pill px-2 font-regular">
                                {" "}
                                Active{" "}
                              </span>
                            </div>
                            <span className="body-4 text-muted">
                              {" "}
                              Fundraising goal added.{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex justify-content-end">
                            <button
                            
                              className="btn btn-link btn-xs px-1 py-0"
                              data-bs-toggle="modal"
                              data-bs-target="#fundraiseValueModal"
                            >
                              Activate
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3 mb-3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* reset value Modal */}
      <div
        className="modal fade"
        id="resetValueModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
            <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../img/leave.png")}
                width={56}
                height={56}
                alt="delete warning"
              />
              <h2 className="mt-3">Do you want to reset the budget? </h2>
              <p>
              By resetting the budget, the value in the budget details will be empty and auto activates budget mode “Start from scratch”. 
              </p>
            </div>

            <div className="modal-footer">
              <button className="btn btn-link rounded-pill btn-xs" data-bs-dismiss="modal"
                aria-label="Close">
                Cancel
              </button>
              <button className="btn btn-primary rounded-pill btn-xs" onClick={notify}>
                Yes, reset <Spinner />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* start from scratch Modal */}
      <div
        className="modal fade"
        id="scratchModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
            <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../img/leave.png")}
                width={56}
                height={56}
                alt="delete warning"
              />
              <h2 className="mt-3">Do you want to change the budget to “Start from Scratch”? </h2>
              <p>
              By changing the budget, budget details will be empty initially. But the changes in the uploaded file will still be saved the next time you access it.
              </p>
            </div>

            <div className="modal-footer">
              <button className="btn btn-link rounded-pill btn-xs" data-bs-dismiss="modal"
                aria-label="Close">
                Cancel
              </button>
              <button className="btn btn-primary rounded-pill btn-xs">
                Yes, change
              </button>
            </div>
          </div>
        </div>
      </div>
       {/* start from fileupload Modal */}
       <div
        className="modal fade"
        id="fileUploadBudgetModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
            <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../img/leave.png")}
                width={56}
                height={56}
                alt="delete warning"
              />
              <h2 className="mt-3">Do you want to change the budget to “uploaded file”? </h2>
              <p>
              By changing the budget, budget details will reflected according to uploaded file. But the changes in the suggested budget based on fundraising goal will not be saved the next time you access it.
              </p>
            </div>

            <div className="modal-footer">
              <button className="btn btn-link rounded-pill btn-xs" data-bs-dismiss="modal"
                aria-label="Close">
                Cancel
              </button>
              <button className="btn btn-primary rounded-pill btn-xs">
                Yes, change
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* start from fundraise Modal */}
      <div
        className="modal fade"
        id="fundraiseValueModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
            <button
                type="button"
                className="btn-close p-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close text-n1 h2"></span>
              </button>
            </div>
            <div className="modal-body text-center px-4">
              <img
                src={require("../../../img/leave.png")}
                width={56}
                height={56}
                alt="delete warning"
              />
              <h2 className="mt-3">Do you want to change the budget to “suggested budget based on fundraising goal”? </h2>
              <p>
              By changing the budget, budget details will reflected according to suggested budget. But the changes in the uploaded file will be saved the next time you access it.
              </p>
            </div>

            <div className="modal-footer">
              <button className="btn btn-link rounded-pill btn-xs" data-bs-dismiss="modal"
                aria-label="Close">
                Cancel
              </button>
              <button className="btn btn-primary rounded-pill btn-xs">
                Yes, change
              </button>
            </div>
          </div>
        </div>
      </div>
     
    </>
  );
};

export default BudgetContent;
