import CheckSuccess from "img/svg/checkSuccess"
import FileIcon from "img/svg/fileIcon"
import TrashIcon from "img/svg/TrashIcon"
import React from "react"

const FileUpload = ({
  file,
  setFile,
  generateFileNameOrError,
  isFileUploadPending,
  isFileUploadSuccess,
  handleInputFileChange
}: {
  file: File | null
  setFile: React.Dispatch<React.SetStateAction<File | null>>
  generateFileNameOrError: () => (JSX.Element | undefined)
  isFileUploadPending: boolean
  isFileUploadSuccess: boolean
  handleInputFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <div className="row" >
      <div className="col-md-12">
        <div className="uploadedCard uploadedCard-danger mb-0 flex-wrap">
          <div className="uploadedCard-icon">
            <div className="iconBox">
              <FileIcon />
            </div>

            <div className="iconInfo">
              <span className="iconInfo-title">
                {file?.name}
              </span>
              {generateFileNameOrError()}
              {isFileUploadPending ? (
                <>Loading...</>
              ) : (
                isFileUploadSuccess && (
                  <span className="iconInfo-status">
                    <CheckSuccess /> File uploaded
                  </span>
                )
              )}
            </div>
          </div>
          <div className="uploadedCard-info">
            <div className="file-btn">
              <button className="btn btn-link rounded-pill text-primary">
                Change File
                <input
                  type="file"
                  name="file"
                  onChange={handleInputFileChange}
                />
              </button>
            </div>

            <button
              className="btn btn-xs"
              type="button"
              onClick={() => setFile(null)}
            >
              <TrashIcon />
            </button>
          </div>
        </div>
      </div>
    </div >

  )
}
export default FileUpload;