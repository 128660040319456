import { useQuery } from "@tanstack/react-query";
import CreateAuthAxiosInstance from "utils/authAxios";
import { globalQueryConfig } from "utils/reactQuery";

const useCheckDashboardCompletedStatus = () => {
  const authAxios = CreateAuthAxiosInstance();
  const userId = localStorage.getItem("userId");

  async function getUserData() {
    const response = await authAxios.get(`/api/v1/user/info/${userId}`);
    return response.data;
  }

  const { data: userData } = useQuery({
    queryKey: ["user-Data"],
    queryFn: getUserData,
    ...globalQueryConfig,
  });

  return {
    userData,
  };
};

export default useCheckDashboardCompletedStatus;
