import { UseMutateFunction } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { isValidFileType } from "components/pages/budget/common/CheckValidFile";
import FileUpload from "components/pages/budget/common/upload-file/components/FileUpload";
import InitialFile from "components/pages/budget/common/upload-file/components/InitialFile";
import NoFile from "components/pages/budget/common/upload-file/components/NoFile";
import { IBudgetFileDetails } from "components/pages/dashboard/account-setup/Budget";
import InfoIconDanger from "img/svg/InfoIconDanger";
import { fileSizeLimit } from "utils/budgetFileSizeLimit";
import { dateFormatter, formatFileSize } from "utils/uploadFile";

interface IBudgetFile {
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  error: string | null;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  budgetDetailsFromApi: IBudgetFileDetails;
  isFileUploadPending: boolean;
  isFileUploadSuccess: boolean;
  mutateDeleteBudgetFile: UseMutateFunction<
    AxiosResponse<any, any>,
    Error,
    void,
    unknown
  >;
  handleFileValidation: (validationFile: File) => void;
}

const BudgetFileUpload = ({
  file,
  setFile,
  error,
  setError,
  budgetDetailsFromApi,
  isFileUploadPending,
  isFileUploadSuccess,
  mutateDeleteBudgetFile,
  handleFileValidation,
}: IBudgetFile) => {
  const currentDate = new Date();

  function generateFileNameOrError() {
    if (!file) return;

    if (isValidFileType(file) && file.size <= fileSizeLimit && !error) {
      return (
        <p className="iconInfo-desc">
          {formatFileSize(file?.size)},
          {dateFormatter(currentDate.toISOString())}
        </p>
      );
    }
    const errorMsg: string[] = [];
    if (file.size > fileSizeLimit) {
      errorMsg.push(" File size exceeds 20 KB.");
    }
    if (!isValidFileType(file)) {
      errorMsg.push(
        " File type not valid. Please enter a file of format xlsx."
      );
    }
    if (error && Array.isArray(error)) {
      error.forEach((error: string) => errorMsg.push(error));
    }
    return (
      <div className={`${errorMsg?.length > 1 && "messageWrapper"}`}>
        {/* if error */}
        <span className="iconInfo-status">
          <div className="text-danger">
            {!!errorMsg?.length &&
              errorMsg.map((msg, index) => {
                return (
                  <span className="d-block">
                    <InfoIconDanger />
                    <strong> Error {errorMsg?.length > 1 && index + 1}</strong>:
                    {msg}
                  </span>
                );
              })}
          </div>
        </span>
      </div>
    );
  }

  const handleInputFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    if (!(e.target.files?.length && e.target.files?.[0])) return;

    const tempFile = e.target.files[0];
    setFile(tempFile);

    handleFileValidation(tempFile);
  };

  return (
    <>
      {file ? (
        <FileUpload
          file={file}
          setFile={setFile}
          generateFileNameOrError={generateFileNameOrError}
          isFileUploadPending={isFileUploadPending}
          isFileUploadSuccess={isFileUploadSuccess}
          handleInputFileChange={handleInputFileChange}
        />
      ) : budgetDetailsFromApi ? (
        <InitialFile
          budgetDetailsFromApi={budgetDetailsFromApi}
          handleInputFileChange={handleInputFileChange}
          mutateDeleteBudgetFile={mutateDeleteBudgetFile}
        />
      ) : (
        <>
          {/* before upload */}
          <NoFile handleInputFileChange={handleInputFileChange} />
        </>
      )}
    </>
  );
};

export default BudgetFileUpload;
